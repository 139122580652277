import React, { useEffect, useRef } from 'react';
import axios from 'axios';

const JitsiMeeting = ({ roomName, user }) => {
  const jitsiContainerRef = useRef(null);

  useEffect(() => {
    const fetchTokenAndInitialize = async () => {
      try {
        // Incluindo o campo isVeterinary no payload
        const userWithVeterinaryFlag = {
          ...user,
          isVeterinary: true // Adiciona ou sobrescreve a propriedade isVeterinary como true
        };

        console.log('Sending user data:', userWithVeterinaryFlag); // Verifica o JSON enviado

        const response = await axios.post('https://vidapet.tech/api/calls/generate-jwt.php', userWithVeterinaryFlag);
        const { token } = response.data;

        if (jitsiContainerRef.current) {
          const domain = '8x8.vc';
          const options = {
            roomName: `vpaas-magic-cookie-fee39c2baf79451b9e8c3c3860389870/${roomName}`,
            width: '90%',
            height: '90%',
            parentNode: jitsiContainerRef.current,
            configOverwrite: {
                prejoinPageEnabled: false,
                // Remova ou ajuste qualquer recurso não reconhecido
            },
            interfaceConfigOverwrite: {
                HIDDEN_DOMAIN_INDICATOR: false,
                // Remova 'speaker-selection' se estiver definido
            },
            jwt: token
        };
        
          const api = new window.JitsiMeetExternalAPI(domain, options);
          return () => api.dispose();
        }
      } catch (error) {
        console.error('Failed to fetch JWT', error);
      }
    };

    fetchTokenAndInitialize();
  }, [roomName, user]);

  return <div ref={jitsiContainerRef} style={{ width: '90%', height: '600px' }}></div>;
};

export default JitsiMeeting;
