import React, { useState } from 'react';
import styled from 'styled-components';
import { auth, provider } from '../../firebase';  // Supondo que o Firebase já esteja configurado
import { FcGoogle } from 'react-icons/fc';
import Modal from 'react-modal';

const Login = ({ closeLogin }) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Para abrir o modal
  const [loading, setLoading] = useState(false); // Para mostrar carregamento
  const [authUrl, setAuthUrl] = useState(''); // Para armazenar a URL de autenticação

  const handleGoogleLogin = async () => {
    try {
      setLoading(true);
      setIsModalOpen(true);  // Abre o modal de login

      // Inicia o processo de login com o Google via popup
      const result = await auth.signInWithPopup(provider);
      setLoading(false);

      // Se o login for bem-sucedido
      if (result.user) {
        closeLogin();
      }
    } catch (error) {
      setLoading(false);
      console.error('Erro ao fazer login:', error);
    }
  };

  return (
    <Wrapper>
      <LoginSection>
        <Divider />
        
        <GoogleLoginButton onClick={handleGoogleLogin}>
          <FcGoogle className='logo' />
          <p>Acesso com Google</p>
        </GoogleLoginButton>

        <Divider />
      </LoginSection>

      {/* Modal para o Popup do Google (simulando um modal com o login dentro) */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Login com Google"
        style={modalStyles}
      >
        <ModalContent>
          <h2>{loading ? 'Aguarde... Redirecionando...' : 'Login bem-sucedido!'}</h2>

          {loading ? (
            <LoadingSpinner />
          ) : (
            <iframe
              src={authUrl}  // Aqui você coloca a URL do popup
              width="100%"
              height="500"
              title="Login Google"
              frameBorder="0"
            ></iframe>
          )}
        </ModalContent>
      </Modal>
    </Wrapper>
  );
};

export default Login;

const Wrapper = styled.div`
  width: 100%;
  padding: 8px 10px;
  background: #333;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const LoginSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #555;
  margin: 8px 0;
`;

const GoogleLoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #db4437;
  width: 100%;
  padding: 8px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;

  .logo {
    margin-right: 5px;
    font-size: 14px;
  }

  p {
    margin: 0;
    font-size: 14px;
  }

  &:hover {
    background-color: #c5372c;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    borderRadius: '8px',
  },
};

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3; /* Light gray */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

