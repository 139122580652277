import React, { useState, useEffect } from 'react';
import axios from 'axios';
import VetList from './VetList';
import JitsiMeeting from './JitsiMeeting';
import CallPayments from './CallPayments';
import './VetCall.css';

const VetCall = () => {
  const [roomName, setRoomName] = useState('');
  const [error, setError] = useState('');
  const [isListVisible, setIsListVisible] = useState(true);
  const [deviceStatus, setDeviceStatus] = useState('');
  const [userId, setUserId] = useState(null);
  const [selectedVetId, setSelectedVetId] = useState(null); // Estado para armazenar o ID do veterinário selecionado
  const [paymentPending, setPaymentPending] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true); // Modal visível ao carregar o componente

  useEffect(() => {
    const loggedUserId = sessionStorage.getItem('userId');
    setUserId(loggedUserId);

    const checkDeviceStatus = async () => {
      try {
        const deviceResponse = await axios.get('https://vidapet.tech/api/calls/get_device_status.php');
        setDeviceStatus(deviceResponse.data.status);
      } catch (error) {
        console.error('Erro ao verificar status do dispositivo:', error);
        setError('Erro ao verificar status do dispositivo.');
      }
    };

    checkDeviceStatus();
  }, []);

  const handleSelectRoom = (roomName, vetId) => {
    setRoomName(roomName);
    setSelectedVetId(vetId); // Atualiza o ID do veterinário selecionado
    setIsListVisible(false);
  };

  const handleStartCall = async () => {
    if (paymentSuccessful) {
      try {
        const response = await axios.post('https://vidapet.tech/api/calls/register_call.php', {
          user_id: userId,
          room_name: roomName,
          vet_id: selectedVetId, // Inclui o ID do veterinário selecionado
        });
  
        if (response.data.status === 'success') {
          alert('Chamada registrada com sucesso!');
        } else {
          alert('Erro ao registrar a chamada: ' + response.data.message);
        }
      } catch (error) {
        console.error('Erro ao iniciar a chamada:', error);
        alert('Erro ao registrar a chamada. Tente novamente.');
      }
    } else {
      setPaymentPending(true);
    }
  };
 
  const handlePaymentSuccess = () => {
    setPaymentSuccessful(true);
    setPaymentPending(false);
  };

  const handlePaymentFailure = () => {
    setPaymentSuccessful(false);
    setPaymentPending(false);
    alert('Falha no pagamento. Tente novamente.');
  };

  const handleToggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false); // Fecha o modal quando o usuário clicar em "Fechar"
  };

  return (
    <div className="vet-call-container">
      {/* Modal de Instruções */}
      {isModalVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Passo a Passo para a Teleconsulta</h3>
            <ol>
              <li><strong>Passo 1:</strong> Escolha a sala de atendimento veterinário disponível.</li>
              <li><strong>Passo 2:</strong> Inclua seus dados para o atendimento.</li>
              <li><strong>Passo 3:</strong> Realize o pagamento via Pix. Copie e cole o QR Code fornecido.</li>
              <li><strong>Passo 4:</strong> Após o pagamento ser confirmado, a sala de teleconsulta será aberta.</li>
            </ol>
            <button onClick={handleCloseModal} className="close-modal-btn">Fechar</button>
          </div>
        </div>
      )}
      
      <button onClick={handleToggleListVisibility} className="toggle-list-btn">
        {isListVisible ? 'Minimizar Lista' : 'Mostrar Lista'}
      </button>

      {deviceStatus === 'Off' ? (
        <div className="error-message">Telemedicina não habilitada para este usuário</div>
      ) : (
        <>
          {isListVisible && (
            <>
              <p>Encontre uma sala de Atendimento disponível na lista abaixo:</p>
              <VetList onSelectRoom={handleSelectRoom} />
            </>
          )}
        </>
      )}
      
      {roomName && !paymentPending && (
        <>
          <p>Você selecionou a sala: {roomName}</p>
          <p>Clique em "Iniciar Chamada Veterinária" para entrar na sala de Atendimento.</p>
          <button onClick={handleStartCall} className="start-call-btn">Iniciar Chamada Veterinária</button>
        </>
      )}

      {roomName && paymentPending && (
        <CallPayments
          userId={userId}
          roomName={roomName}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentFailure={handlePaymentFailure}
        />
      )}

      {roomName && paymentSuccessful && <JitsiMeeting roomName={roomName} />}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default VetCall;
