import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Telemedicine.css';
import heroImage from '../../imagens/TeleVet001.webp';
import secondaryImage from '../../imagens/TeleVet002.webp';

const Telemedicine = () => {
  const navigate = useNavigate();

  // Redireciona para o WhatsApp com mensagens específicas
  const handleWhatsAppClick = (type) => {
    const messages = {
      vet: encodeURIComponent(
        `Quero expandir minha área de atuação com Telemedicina Veterinária VidaPet.Tech:\n` +
        `- Nome Completo:\n` +
        `- Cidade:\n` +
        `- Nome da Clínica:\n` +
        `- Especialidades:\n` +
        `- WhatsApp para contato:`
      ),
      user: encodeURIComponent(
        `Quero Participar do Projeto Piloto Video Chamada Veterinária VidaPet.Tech:\n` +
        `- Nome Completo:\n` +
        `- Cidade:\n` +
        `- Email:\n` +
        `- WhatsApp para contato:`
      ),
    };

    window.open(`https://wa.me/5521982992635?text=${messages[type]}`, '_blank');
  };

  // Redireciona para o Dashboard
  const redirectToDashboard = () => {
    window.location.href = '/dashboard';
  };

  // Redireciona para o componente TelemedicineVet
  const redirectToTelemedicineVet = () => {
    navigate('/TelemedicineVet');
  };

  return (
    <div className="telemedicine-container">
      {/* Hero Section */}
      <section className="hero-section-grid">
        <div className="hero-content">
          <h1>Teleconsulta Veterinária VidaPet.Tech</h1>
          <p>Cuidados veterinários a apenas uma chamada de distância!</p>

          <div className="hero-features">
            <div className="feature">
              <i className="icon-video"></i>
              <span>Consultas Online</span>
            </div>
            <div className="feature">
              <i className="icon-clock"></i>
              <span>Atendimento 24/7</span>
            </div>
            <div className="feature">
              <i className="icon-shield"></i>
              <span>Segurança Garantida</span>
            </div>
          </div>

          <img src={heroImage} alt="Teleconsulta Veterinária" className="hero-image" />

          <div className="cta-buttons">
            <button className="whatsapp-button" onClick={redirectToTelemedicineVet}>
              Sou Clínica/Veterinário e Quero Atender Meus Pacientes com a Teleconsulta VidaPet.Tech
            </button>
          </div>
        </div>

        {/* Sidebar for Veterinarians */}
        <div className="vet-sidebar">
          <h2>Como Acessar a Sala de Teleconsulta Veterinária</h2>
          <p>
            Para acessar a sala de teleconsulta veterinária, faça login na plataforma VidaPet.Tech,
            acesse a seção de "Gestão de Pets" e selecione a opção de salas de consulta.
            Se ainda não tem cadastro, crie uma conta e garanta que o seu veterinário esteja cadastrado também.
          </p>

          <button className="dashboard-button" onClick={redirectToDashboard}>
            Acessar o Dashboard (Somente para clientes cadastrados)
          </button>

          <img src={secondaryImage} alt="Consultas Veterinárias Online" className="secondary-image" />
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section">
        <h2>Benefícios</h2>
        <div className="benefits-grid">
          <div className="benefit-card">
            <i className="icon-lightbulb"></i>
            <h3>Comodidade</h3>
            <p>Realize consultas veterinárias no conforto da sua casa.</p>
          </div>
          <div className="benefit-card">
            <i className="icon-heart"></i>
            <h3>Saúde do Pet</h3>
            <p>Receba conselhos médicos para a saúde do seu pet a qualquer momento.</p>
          </div>
          <div className="benefit-card">
            <i className="icon-globe"></i>
            <h3>Atendimento Global</h3>
            <p>Conecte-se com veterinários de qualquer lugar do mundo.</p>
          </div>
        </div>
      </section>

      {/* Instructions Section */}
      <section className="instructions-section">
        <h2>Instruções de Acesso</h2>
        <p>
          Se você ainda não tem uma conta, crie uma e adicione seu veterinário para acessar
          os serviços de teleconsulta. Simples e rápido!
        </p>
      </section>

      {/* Testimonial Section */}
      <section className="testimonial-section">
        <h2>O que dizem nossos usuários</h2>
        <blockquote>
          "A VidaPet.Tech tornou a consulta veterinária mais fácil e acessível. Agora posso cuidar da saúde do meu pet sem sair de casa!"
        </blockquote>
      </section>

      <footer>
        <p>VidaPet.Tech - Todos os direitos reservados.</p>
      </footer>
    </div>
  );
};

export default Telemedicine;
