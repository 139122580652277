import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import JitsiMeeting from '../Components/VetCalls/JitsiMeeting';
import HealthEventForm from '../Components/HealthEventForm';
import VetList from '../Components/VetCalls/VetList';
import HealthEventsView from '../Components/HealthEventsView';
import styled from 'styled-components';

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'gridView',
})`
  display: ${(props) => (props.gridView ? 'grid' : 'block')};
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`;

const VetDashboard = ({ selectedPetId }) => {
  const [vetId, setVetId] = useState('');
  const [error, setError] = useState('');
  const [roomName, setRoomName] = useState('');
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [pets, setPets] = useState([]);
  const [selectedPet, setSelectedPet] = useState('');
  const [healthEvents, setHealthEvents] = useState([]);  
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [gridView, setGridView] = useState(false);

  const correctPassword = 'vet901';

  const fetchRooms = useCallback(async () => {
    try {
      const response = await axios.get(`https://vidapet.tech/api/calls/get_rooms.php?vetId=${vetId}`);
      setRooms(response.data);
    } catch (error) {
      console.error('Erro ao buscar salas:', error);
      setError('Erro ao buscar salas.');
    }
  }, [vetId]);

  const fetchPets = useCallback(async () => {
    try {
      const response = await axios.get('https://vidapet.tech/api/calls/vet_petList.php');
      if (response.data.status === 'success' && Array.isArray(response.data.pets)) {
        setPets(response.data.pets);
      } else {
        setPets([]);
      }
    } catch (error) {
      console.error('Erro ao buscar pets:', error);
      setError('Erro ao buscar pets.');
    }
  }, []);

  const fetchHealthEvents = useCallback(async (petId) => {
    try {
      const response = await axios.get(`https://vidapet.tech/api/health-events.php?petId=${petId}`);
      if (response.data.status === 'success' && Array.isArray(response.data.events)) {
        setHealthEvents(response.data.events);
      } else {
        setHealthEvents([]);
      }
    } catch (error) {
      console.error('Erro ao buscar eventos de saúde:', error);
      setError('Erro ao buscar eventos de saúde.');
    }
  }, []);

  const handleAddHealthEvent = async (eventDescription) => {
    if (selectedPet) {
      try {
        const eventType = 'Atendimento';
        const userDate = new Date().toISOString().split('T')[0];

        const formData = new FormData();
        formData.append('eventType', eventType);
        formData.append('eventDescription', eventDescription);
        formData.append('petId', selectedPet);
        formData.append('userDate', userDate);

        const response = await axios.post('https://vidapet.tech/health-events.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log('Evento de saúde adicionado com sucesso', response.data);
        fetchHealthEvents(selectedPet);
      } catch (error) {
        console.error('Erro ao adicionar evento de saúde:', error);
      }
    }
  };

  useEffect(() => {
    if (vetId) {
      fetchRooms();
      fetchPets();
    }
  }, [vetId, fetchRooms, fetchPets]);

  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      setError('Senha incorreta.');
    }
  };

  if (!isAuthenticated) {
    return (
      <div>
        <h1>Painel de Veterinário</h1>
        <label htmlFor="passwordInput">Digite a senha para acessar:</label>
        <input
          type="password"
          id="passwordInput"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Insira a senha"
        />
        <button onClick={handlePasswordSubmit}>Entrar</button>
        {error && <p>{error}</p>}
      </div>
    );
  }

  return (
    <div>
      <h1>Painel de Veterinário</h1>
      <div>
        <label htmlFor="vetIdInput">Digite seu ID de Veterinário:</label>
        <input
          type="text"
          id="vetIdInput"
          value={vetId}
          onChange={(e) => setVetId(e.target.value)}
          placeholder="Insira seu ID"
        />
        <button onClick={fetchRooms}>Mostrar Salas</button>
      </div>
      {vetId && <VetList rooms={rooms} onSelectRoom={(roomName) => setRoomName(roomName)} />}
      <button onClick={() => alert('Função de criar sala chamada.')}>Criar Nova Sala</button>
      {roomName && <JitsiMeeting roomName={roomName} />}
      <div>
        <h2>Escolher Pet para Atendimento</h2>
        <select
          value={selectedPet}
          onChange={(e) => setSelectedPet(e.target.value)}
          style={{ color: 'black' }}
        >
          <option value="">Selecione um pet</option>
          {pets.map((pet) => (
            <option key={pet.petId} value={pet.petId}>
              {pet.petName || 'Nome não disponível'} - {pet.breed || 'Sem raça'}
            </option>
          ))}
        </select>
        <button onClick={() => fetchHealthEvents(selectedPet)}>Ver Eventos de Saúde</button>
      </div>
      <button onClick={() => setGridView(!gridView)}>
        {gridView ? 'Visualizar como lista' : 'Visualizar como grade'}
      </button>
      {selectedPet && (
        <div>
          <HealthEventsView
            petId={selectedPet}
            gridView={gridView}
            reloadEvents={() => fetchHealthEvents(selectedPet)}
          />
          <HealthEventForm petId={selectedPet} onSubmit={handleAddHealthEvent} />
        </div>
      )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default VetDashboard;