import React, { useState } from 'react';
import TermsModal from './TermsModal';
import PrivacyModal from './PrivacyModal';  // Importando o PrivacyModal
import LicenseModal from './LicenseModal'; // Importando o LicenseModal
import './AgreementsAndPrivacy.css';

const AgreementsAndPrivacy = () => {
  const [activeModal, setActiveModal] = useState(null);

  const openModal = (modalType) => setActiveModal(modalType);
  const closeModal = () => setActiveModal(null);

  return (
    <div className="agreements-container">
      <h2>Acordos, Licenças e Privacidade</h2>
      <p>Ao utilizar nossa plataforma, você concorda com os seguintes termos e políticas:</p>
      <ul>
        <li>
          <button onClick={() => openModal('terms')} className="link-button">
            Termos de Uso
          </button>
        </li>
        <li>
          <button onClick={() => openModal('privacy')} className="link-button">
            Política de Privacidade
          </button>
        </li>
        <li>
          <button onClick={() => openModal('license')} className="link-button">
            Acordo de Licença
          </button>
        </li>
      </ul>
      <p>
        Por favor, leia cuidadosamente cada um dos documentos acima. Ao continuar a utilizar nossa plataforma, você confirma que leu e concorda com todos os termos e políticas.
      </p>

      {/* Renderizando o modal com base no termo ativo */}
      {activeModal === 'terms' && (
        <TermsModal
          modalType={activeModal}
          onClose={closeModal}
        />
      )}
      {activeModal === 'privacy' && (
        <PrivacyModal onClose={closeModal} />
      )}
      {activeModal === 'license' && (
        <LicenseModal onClose={closeModal} />
      )}
    </div>
  );
};

export default AgreementsAndPrivacy;
