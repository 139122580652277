import React from 'react';
import { useParams } from 'react-router-dom';
import './BlogPostDetail.css';
import VacinacaoImage from '../../../imagens/vacinacoes1.png';

const blogPosts = [
  {
    id: 1,
    title: 'VidaPet.Tech é Selecionada para o Programa de Aceleração do Ibmec Hubs Rio de Janeiro',
    date: '30/09/2024',
    introduction: 'Com grande entusiasmo, anunciamos que a VidaPet.Tech foi escolhida para integrar o renomado programa de aceleração do Ibmec Hubs Rio de Janeiro, um dos principais centros de inovação do Brasil.',
    body1: 'Esse marco valida o esforço da nossa equipe e evidencia o impacto significativo que nossa plataforma vem gerando no setor de tecnologia voltada à saúde animal. Nossa missão é clara: melhorar a qualidade de vida dos pets e facilitar a gestão de saúde para seus tutores e profissionais da área.',
    body2: 'Através da tecnologia, buscamos simplificar o acesso a informações essenciais sobre vacinas, check-ups, exames e tratamentos, garantindo que os animais de estimação estejam sempre saudáveis. A participação no programa de aceleração oferece uma oportunidade valiosa para impulsionar nossa proposta.',
    body3: 'Nos próximos meses, teremos acesso a mentorias de alto nível e suporte em áreas estratégicas, além de uma rede de contatos que poderá abrir portas para novas parcerias e investimentos. Estamos ansiosos para aproveitar ao máximo essa oportunidade e escalar a VidaPet.Tech para novos patamares.',
    conclusion: 'Agradecemos a todos os nossos parceiros, investidores e, especialmente, aos nossos usuários, que confiam e continuam a acreditar em nossa proposta. Juntos, vamos transformar a maneira como a saúde pet é gerida no Brasil e no mundo.',
    imageUrl: 'https://midias.jb.com.br/_midias/jpg/2021/04/07/ibmec-592121.jpg',
    tags: ['Vida Pet', 'Novidade'],
    authorImage: 'https://vidapet.tech/static/media/VidaPetTechLogo2024Completa01.0bc38550427b686f8661.png',
    author: 'Por Camilla Ferreira, editora VidaPet.Tech',
    featuredPosts: [
      { title: 'VidaPet.Tech entra para o programa de aceleração do Ibmec Hubs Rio de Janeiro', date: '30/09/2024', imageUrl: 'https://blog.ibmec.br/wp-content/uploads/2021/03/Logo-Nova-Marca-Ibmec_Hubs-1024x783.png' },
      { title: 'Quantas vacinas um cão precisa tomar durante sua vida?', date: '14/09/2024', imageUrl: 'https://labovet.com.br/wp-content/uploads/2019/03/vacina_caes_widexl.png' },
    ],
    // Sem vaccinationDetails e digitalCardMessage para manter a coerência original desse post.
  },
  {
    id: 2,
    title: 'Quantas vacinas um cão precisa tomar durante sua vida?',
    date: '14/09/2024',
    introduction: 'Saber quais vacinas um cão precisa tomar ao longo da vida é fundamental para manter a saúde do animal e prevenir doenças graves.',
    body1: 'Durante os primeiros meses de vida, os filhotes recebem uma série de vacinas essenciais, como a V8 ou V10, antirrábica e outras vacinas que protegem contra doenças infecciosas comuns. Este período é crucial para construir a imunidade do animal.',
    body2: 'Ao longo da vida adulta, reforços periódicos dessas vacinas são necessários. Normalmente, as doses de reforço são anuais, garantindo que o cão mantenha seus anticorpos ativos contra patógenos que circulam no ambiente.',
    body3: 'Além disso, em determinadas regiões ou situações específicas, vacinas extras podem ser recomendadas pelo veterinário, como a contra Leptospirose, Gripe Canina (Bordetella) ou Giárdia. Cada caso deve ser avaliado individualmente.',
    conclusion: 'Manter o calendário vacinal em dia é uma forma eficaz de proteger a saúde do seu animal e evitar despesas com tratamentos. Consulte sempre um veterinário de confiança, mantenha os registros atualizados e garanta que seu cão viva uma vida longa e saudável.',
    imageUrl: 'https://labovet.com.br/wp-content/uploads/2019/03/vacina_caes_widexl.png',
    tags: ['Saúde Pet', 'Vacinação', 'Bem-Estar Animal'],
    authorImage: 'https://vidapet.tech/static/media/VidaPetTechLogo2024Completa01.0bc38550427b686f8661.png',
    author: 'Por Camilla Ferreira, editora VidaPet.Tech',
    featuredPosts: [
      { title: 'Calendário de Vacinação para Filhotes', date: '01/08/2024', imageUrl: 'https://example.com/filhotes_vacinacao.jpg' },
      { title: 'Como Prevenir Doenças Comuns em Cães', date: '20/07/2024', imageUrl: 'https://example.com/doencas_caes.jpg' },
    ],
    // Sem vaccinationDetails e digitalCardMessage aqui, a não ser que queira adicionar.
    // Mantemos sem para não "contaminar" o post com assuntos não relacionados.
  },
  {
    id: 3,
    title: 'Campanha de Vacinação Antirrábica Animal em Maricá',
    date: '23/11/2024',
    introduction: 'A Prefeitura de Maricá, através da Secretaria Municipal de Saúde, realizará nos dias 23 e 30 de novembro de 2024 a Campanha de Vacinação Antirrábica Animal.',
    body1: 'A ação visa imunizar cães e gatos saudáveis, com idade a partir de três meses, que não estejam em estado gestacional. Esta campanha será realizada em dois sábados para garantir que mais tutores possam vacinar seus animais contra a raiva.',
    body2: 'No dia 23 de novembro, a vacinação será oferecida em 33 postos nos distritos do Centro e Ponta Negra, funcionando das 9h às 17h. Já no dia 30 de novembro, a vacinação ocorrerá em 17 locais nos distritos de Inoã e Itaipuaçu. É importante ressaltar que os servidores públicos não poderão segurar os animais.',
    body3: 'Os cães devem preferencialmente usar focinheira, além de coleira e guia, para garantir a segurança de todos. Já os gatos devem ser transportados em caixas apropriadas, evitando fugas e acidentes.',
    conclusion: 'Micheli Ferreira, coordenadora da Vigilância em Saúde de Maricá, reforça a importância da vacinação, pois a raiva é uma doença grave que pode afetar tanto os animais quanto os seres humanos. Ela incentiva todos os tutores a levarem seus pets à vacinação, que é gratuita e essencial para a saúde pública.',
    imageUrl: VacinacaoImage,
    tags: ['Vacinação', 'Raiva', 'Saúde Animal'],
    authorImage: 'https://vidapet.tech/static/media/VidaPetTechLogo2024Completa01.0bc38550427b686f8661.png',
    author: 'Por Camilla Ferreira, editora VidaPet.Tech',
    featuredPosts: [
      { title: 'Campanha de Vacinação Antirrábica Animal', date: '23/11/2024', imageUrl: 'https://example.com/related-post-image.jpg' },
    ],
    vaccinationDetails: [
      {
        date: '23/11/2024',
        location: 'Centro e Ponta Negra',
        places: ['USF Central', 'Secretaria de Proteção Animal', 'Gerência de Frota', 'Praça do Lelei (Caju)', 'USF Bairro da Amizade'],
      },
      {
        date: '30/11/2024',
        location: 'Inoã e Itaipuaçu',
        places: ['Escola Municipalizada de Inoã', 'USF Chácara de Inoã', 'Escola Municipal Aniceto Elias', 'USF Inoã II'],
      },
    ],
    digitalCardMessage: 'Aproveite para Criar a Carteirinha Digital de Saúde do Seu Pet! 🐾 Além de proteger seu animal com a vacina antirrábica, aproveite a oportunidade para criar a Carteirinha Digital de Saúde Pet na plataforma VidaPet.tech! Assim você mantém todo o histórico de vacinas e cuidados em um único lugar.',
  },
];

const BlogPostDetail = () => {
  const { id } = useParams();
  const post = blogPosts.find((p) => p.id === parseInt(id));

  if (!post) {
    return <p>Post not found.</p>;
  }

  return (
    <div className="blog-post-detail">
      <h1>{post.title}</h1>
      <p className="author">{post.author}</p> 
      <img src={post.imageUrl} alt={post.title} className="large-image" />
      
      <div className="content-wrapper">
        <div className="main-content1">
          <div className="post-content">
            <h2>Introdução</h2>
            <p>{post.introduction}</p>
            <h2>Práticas que podem ajudar a todos</h2>
            <p>{post.body1}</p>
            <h2>Implementando Soluções Digitais</h2>
            <p>{post.body2}</p>
            <h2>Boas Práticas</h2>
            <p>{post.body3}</p>
            <h2>Conclusão</h2>
            <p>{post.conclusion}</p>
            
            <div className="tags">
              {post.tags && post.tags.map((tag, index) => (
                <span key={index} className="tag">{tag}</span>
              ))}
            </div>
            
            {post.vaccinationDetails && (
              <div className="vaccination-details">
                {post.vaccinationDetails.map((vaccination, index) => (
                  <div key={index}>
                    <h3>Vacinação: {vaccination.date}</h3>
                    <h4>{vaccination.location}</h4>
                    <ul>
                      {vaccination.places && vaccination.places.map((place, i) => (
                        <li key={i}>{place}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
            
            {post.digitalCardMessage && (
              <div className="digital-card">
                <p>{post.digitalCardMessage}</p>
                <a 
                  href="https://vidapet.tech/dashboard" 
                  className="cta-button"
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  Faça a carteirinha digital do seu pet!
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostDetail;
