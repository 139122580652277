import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import './App.css';

// Componentes básicos e páginas
import PageHeader from './components/PageHeaders';
import PageFooter from './components/Footer/Footer';
import Home from "./pages/Home/Home";
import SobreNos from './pages/SobreNos/IndexSobreNos';
import Blog from './pages/Blog/Index';
import BlogDet from './pages/Blog/Components/BlogPostDetail';
import BlogEditor from './pages/Blog/Post/PostEditor';


// Termos e Privacidade
import AgreementsAndPrivacy from './pages/Acordos/AgreementsAndPrivacy'; // Importando o componente


// Login
import Login from "./pages/Login/On/Login";
import Registro from "./pages/Login/Cadastro/SignUp";
import LoginAppPage from './pages/Login/LoginAppPage';  // Adapte o caminho conforme necessário


// Carteirinha
import RescueDashboard from "./pages/Resgate/RescueDashboard";  
import RescueDetails from "./pages/Resgate/Components/RescueDetails";  
import Dashboard from './pages/Dashboard/Dashboard';
import DashboardHomePartner from './pages/Dashboard/DashboardHomePartner';
import Carteirinha from './pages/Dashboard/PetCard/Carteirinha';

// Telemedicina
import VetCall from './pages/Dashboard/Components/VetCalls/VetCall';
import VetDashboard from './pages/Dashboard/VetDash/VetDashboard';
import Telemedicine from './pages/Telemedicine/Telemedicine';
import TelemedicineVet from './pages/Telemedicine/TelemedicineVet';
import QrCode from './pages/Resgate/QrCode/QrCode';

// MarketPlace
import MarketPlace from './pages/MarketPlace/MarketPlace copy';
import EsqueciSenha from './pages/Login/EsqueciSenha';
import ConfirmSenha from './pages/Login/ConfirmSenha';
import Item from './pages/MarketPlace/components/Lojas/Products/ProductDetail';
import Lojas from './pages/MarketPlace/components/Lojas/index';
import LojaDetalhes from './pages/MarketPlace/components/Pages/LojaDetalhes';
import AddLojas from './pages/MarketPlace/components/Pages/Adicionar/AddLojas';
import Parceiro from './pages/MarketPlace/Dashboard-MK/Login/Cadastro';
import Criarloja from './pages/MarketPlace/Dashboard-MK/Login/CriarLoja';
import Confirmacao from './pages/MarketPlace/Dashboard-MK/Login/Confirmacao';
import CadastroUsuario from './pages/MarketPlace/Dashboard-MK/Login/CadastroUsuario';
import PainelUsuario from './pages/MarketPlace/Dashboard-MK/Painel/Painel';
import LoginMK from './pages/MarketPlace/Dashboard-MK/Login/Usuario/Login-MK';
import Cartalogo from './pages/Cartalogo/Cartalogo';
import ServicoDet from './pages/Cartalogo/Components/ServicoDetalhes';
import AddService from './pages/Cartalogo/Add-Service/Add-Service';
import AddFotos from './pages/Cartalogo/Add-Service/Add-Fotos';


// RESGATE
import AdoptionViewDetails from './pages/Resgate/AdoptionViewDetails';

//WEBHOOKS


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
  }
`;

function App() {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [currentCall, setCurrentCall] = useState(null);

  const handleCallStart = (roomName) => {
    setCurrentCall(roomName);
  };

  const handleJoinCall = (roomName) => {
    setCurrentCall(roomName);
  };

  const handleCallClose = () => {
    setCurrentCall(null);
  };

  const handleSave = (data) => {
    console.log('Dados salvos:', data);
  };

  return (
    <>
      <GlobalStyle />
      <div className="App">
        <Router>
          <PageHeader setMenuIsVisible={setMenuIsVisible} />
          <Routes>
             {/* Rotas do Paginas principais */}
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/index.html" element={<Home />} />
            <Route path="/carteirinha" element={<Carteirinha />} />
            <Route path="/SobreNos" element={<SobreNos />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/DashboardHomePartner" element={<DashboardHomePartner />} />

            <Route path="/blog" element={<Blog />} />
            <Route path="/post/:id" element={<BlogDet />} />
            <Route path="/blogeditor" element={<BlogEditor />} />

            {/* Termos e Privacidade */}
            <Route path="/termos-e-privacidade" element={<AgreementsAndPrivacy />} />


            {/* Rotas do Resgate */}
            <Route path="/resgate" element={<RescueDashboard />} />
            <Route path="/rescues/:id" element={<RescueDetails />} />
            <Route path="/adoptions/:id" element={<AdoptionViewDetails />} />

            
            {/* Rotas do Login */}
            <Route path="/login" element={<Login />} />
            <Route path="/registro" element={<Registro />} />
            <Route path="/esqueci_minha_senha" element={<EsqueciSenha />} />
            <Route path="/LoginAppPage" element={<LoginAppPage />} />

            
            
            {/* Rotas do Telemedicina */}
            <Route path="/Telemedicine" element={<Telemedicine />} />
            <Route path="/TelemedicineVet" element={<TelemedicineVet />} />
            <Route path="/vet-dashboard" element={<VetDashboard onJoinCall={handleJoinCall} />} />        
            <Route path="/qr-code/:petId" element={<QrCode />} />

            {/* Rotas do MarketPlace */}
            <Route path="/marketplace" element={<MarketPlace />} />
            
            <Route path="/AdicionarLojas" element={<AddLojas />} />
            <Route path="/Parceiro" element={<Parceiro />} />
            <Route path="/criarloja" element={<Criarloja />} />
            <Route path="/Confirmacao" element={<Confirmacao />} />
            <Route path="/cadastroUsuario" element={<CadastroUsuario />} />
            <Route path="/painelUsuario" element={<PainelUsuario />} />
            <Route path="/loginMK" element={<LoginMK />} />

            <Route path="/marketplace" element={<MarketPlace />} />

            <Route path="/AddServico" element={<AddService />} />  
            <Route path="/AddFotos" element={<AddFotos />} />  
            <Route path="/Servico" element={<Cartalogo />} />  
            <Route path="/Servicos" element={<Cartalogo />} />            
            <Route path="/servico/:id" element={<ServicoDet />} />

            <Route path="/lojas/:id" element={<LojaDetalhes />} />
            <Route path="/produto/:productId" element={<Item />} />
            
          </Routes>
          {currentCall && <VetCall roomName={currentCall} onClose={handleCallClose} />}
        </Router>
        <PageFooter />        
      </div>
    </>
  );
}

export default App;