import React, { useState, useRef } from 'react';

/**
 * Estilos para o modal de privacidade
 * - modalStyle.overlay: Estilo do fundo escuro do modal, que ocupa toda a tela e centraliza o conteúdo.
 * - modalStyle.content: Estilo da caixa de conteúdo do modal, com bordas arredondadas, padding e altura/ largura definidas.
 * - modalStyle.closeButton: Estilo do botão de fechar (X) no canto superior direito do modal.
 * - modalStyle.header: Estilo para os títulos dentro do modal, com maior destaque.
 * - modalStyle.paragraph: Estilo para os parágrafos com o conteúdo descritivo da política de privacidade.
 * - modalStyle.buttonContainer: Contêiner para os botões de ação (Aceitar e Fechar), com espaço entre eles.
 * - modalStyle.button: Estilo genérico para os botões de ação.
 * - modalStyle.acceptButton: Estilo para o botão de aceitação, com cor verde.
 * - modalStyle.closeButtonStyle: Estilo para o botão de fechar, com cor vermelha.
 */
const modalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    background: 'white',
    padding: '20px',
    maxWidth: '500px', // Largura fixa
    width: '100%',
    borderRadius: '8px',
    position: 'relative',
    overflowY: 'auto',
    maxHeight: '70vh', // Altura fixa
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.6',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  acceptButton: {
    backgroundColor: '#4CAF50', // Cor verde para o botão de aceitar
    color: 'white',
  },
  closeButtonStyle: {
    backgroundColor: '#f44336', // Cor vermelha para o botão de fechar
    color: 'white',
  },
};

/**
 * Componente `PrivacyModal`
 * 
 * O `PrivacyModal` é um componente React que exibe a política de privacidade do serviço VidaPet.Tech. 
 * Ele permite que o usuário leia a política de privacidade e aceite ou feche o modal.
 *
 * Funcionalidades:
 * - Exibição da política de privacidade com scroll
 * - Botão de aceitação para registrar o consentimento do usuário
 * - Botão de fechamento para fechar o modal sem aceitar a política
 *
 * Propriedades:
 * - `userId` (string): Identificador único do usuário, necessário para registrar o aceite da política.
 * - `onClose` (function): Função de callback para fechar o modal.
 */
function PrivacyModal({ userId, onClose }) {
  // Estado para controlar se o botão "Aceitar" deve ser habilitado
  const [isEnabled, setIsEnabled] = useState(false);

  // Referência para detectar quando o usuário alcança o final da política de privacidade
  const endOfPrivacyRef = useRef(null);

  /**
   * Função que lida com o evento de aceitação da política de privacidade.
   * Quando o usuário clica em "Aceitar", um POST é enviado para registrar o aceite no servidor.
   */
  const handleAcceptPrivacy = () => {
    fetch('https://vidapet.tech/api/DB/vidapettech/privacidade.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        console.log('Política de privacidade aceita com sucesso.');
        onClose(); // Fecha o modal após o aceite bem-sucedido
      } else {
        console.error('Erro ao aceitar política de privacidade:', data.error);
      }
    })
    .catch(error => {
      console.error('Erro na rede:', error);
    });
  };

  /**
   * Função de manipulação de scroll.
   * Verifica se o usuário rolou até o final do conteúdo da política de privacidade e habilita o botão "Aceitar".
   */
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollTop + clientHeight === scrollHeight) {
      setIsEnabled(true); // Habilita o botão "Aceitar" quando o final for alcançado
    }
  };

  return (
    <div style={modalStyle.overlay}>
      <div style={modalStyle.content} onScroll={handleScroll}>
        {/* Botão de Fechar */}
        <button style={modalStyle.closeButton} onClick={onClose}>X</button>
        
        {/* Título do Modal */}
        <h2 style={modalStyle.header}>Política de Privacidade - VidaPet.Tech</h2>
        
        {/* Introdução à Política de Privacidade */}
        <p style={modalStyle.paragraph}>
          Ao acessar ou utilizar a plataforma VidaPet.Tech, você concorda com as práticas descritas abaixo em nossa Política de Privacidade.
        </p>

        {/* Seções da Política de Privacidade */}
        <h3 style={modalStyle.header}>1. Coleta de Dados</h3>
        <p style={modalStyle.paragraph}>
          Coletamos dados pessoais e informações de navegação para melhorar a experiência do usuário.
        </p>

        <h3 style={modalStyle.header}>2. Uso dos Dados</h3>
        <p style={modalStyle.paragraph}>
          Seus dados serão usados exclusivamente para fornecer e melhorar nossos serviços.
        </p>

        <h3 style={modalStyle.header}>3. Compartilhamento de Dados</h3>
        <p style={modalStyle.paragraph}>
          Não compartilhamos seus dados pessoais com terceiros sem o seu consentimento, exceto quando exigido por lei.
        </p>

        <h3 style={modalStyle.header}>4. Armazenamento de Dados</h3>
        <p style={modalStyle.paragraph}>
          Seus dados são armazenados de maneira segura e podem ser mantidos enquanto for necessário para cumprir os objetivos descritos nesta política.
        </p>

        <h3 style={modalStyle.header}>5. Seus Direitos</h3>
        <p style={modalStyle.paragraph}>
          Você tem o direito de acessar, corrigir ou excluir seus dados pessoais, conforme as leis de proteção de dados aplicáveis.
        </p>

        <h3 style={modalStyle.header}>6. Alterações na Política</h3>
        <p style={modalStyle.paragraph}>
          O VidaPet.Tech pode alterar esta Política de Privacidade a qualquer momento. Mudanças serão publicadas nesta página.
        </p>

        <h3 style={modalStyle.header}>7. Contato</h3>
        <p style={modalStyle.paragraph}>
          Se você tiver dúvidas sobre nossa Política de Privacidade, entre em contato conosco em contato@vidapet.tech.
        </p>

        {/* Referência para o fim do conteúdo da política */}
        <div ref={endOfPrivacyRef}></div>

        {/* Contêiner de Botões */}
        <div style={modalStyle.buttonContainer}>
          {/* Botão de Aceitar */}
          <button
            style={{ ...modalStyle.button, ...modalStyle.acceptButton }}
            onClick={handleAcceptPrivacy}
            disabled={!isEnabled} // Desabilita o botão até que o final da política seja alcançado
          >
            Aceitar
          </button>
          {/* Botão de Fechar */}
          <button
            style={{ ...modalStyle.button, ...modalStyle.closeButtonStyle }}
            onClick={onClose}
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
}

export default PrivacyModal;
