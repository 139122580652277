import React from 'react';
import './Time.css';
import TeamMemberCard from './TeamMemberCard';

function Equipe() {
    const teamMembers = [
        {
            name: 'Andre',
            role: 'CEO e Fundador do VidaPet.Tech',
            description: 'Com mais de 18 anos de experiência em grandes empresas como Localiza Rent a Car, Santander, Itaú Unibanco e Vivo, Andre Benther é um profissional completo, com sólida expertise em finanças, gestão corporativa, comercial e tecnologia. Certificado em Desenvolvimento de Sistemas e com diversas outras certificações, ele alia seu profundo conhecimento técnico à sua paixão por inovação. Andre idealizou o VidaPet.Tech.',
            socialLinks: [
                { name: 'LinkedIn', url: 'https://www.linkedin.com/in/', icon: 'https://logopng.com.br/logos/linkedin-83.png' },
            ]
        },
        {
            name: 'Bianca',
            role: 'Especialista em Logística e Gestão de Contratos',
            description: 'Bianca Schuenck traz uma vasta experiência corporativa em logística e gestão de contratos, essencial para a operação eficiente do VidaPet.Tech. Sua expertise garante que todas as operações sejam conduzidas de maneira organizada e que os acordos sejam cumpridos rigorosamente.',
            socialLinks: [
                { name: 'LinkedIn', url: 'https://.linkedin.com/in/', icon: 'https://logopng.com.br/logos/linkedin-83.png' },
            ]
        }
    ];

    return (
        <div className="App">
            <header className="App-header">
                <h1>Nossa Equipe</h1>
            </header>
            <main className="App-main">
                <div className="team-members">
                    {teamMembers.map((member, index) => (
                        <TeamMemberCard
                            key={index}
                            name={member.name}
                            role={member.role}
                            photo={member.photo}
                            description={member.description}  // Adiciona a descrição aqui
                            socialLinks={member.socialLinks}
                        />
                    ))}
                </div>
            </main>
        </div>
    );
}

export default Equipe;
