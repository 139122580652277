import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import UserChat from '../Dashboard/Components/Chat/UserChat';
import BlogList from '../Blog/BlogList';

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentFeature, setCurrentFeature] = useState(null);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(true);
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const features = {
    digitalCard: {
      title: "Carteirinha Digital",
      description: [
        "Nunca mais se preocupe com documentos perdidos. A Carteira de Saúde Digital armazena tudo o que você precisa sobre o histórico médico do seu pet, garantindo acesso rápido e seguro em qualquer emergência."
      ],
      link: "/dashboard",
    },
    telemedicine: {
      title: "Telemedicina Veterinária",
      description: [
        "Evite atrasos que podem custar caro. Com a telemedicina VidaPet.Tech, você fala com um veterinário em minutos, recebendo orientações imediatas para cuidar do seu pet em momentos críticos."
      ],
      link: "/telemedicine",
    },
    rescue: {
      title: "Resgate Inteligente",
      description: [
        "Minimize o risco de perder seu pet para sempre. Nosso sistema de alerta envolve a comunidade local, permitindo que você encontre seu pet mais rápido e sem complicações, mesmo em situações de emergência."
      ],
      link: "/resgate",
    },
    marketplace: {
      title: "Marketplace para Pets",
      description: [
        "Garante que seu pet nunca fique sem o essencial. Encontre rações, medicamentos e produtos específicos com facilidade. O Marketplace VidaPet.Tech evita que você perca tempo ou enfrente a falta de itens indispensáveis."
      ],
      link: "/servico",
    },
  };

  useEffect(() => {
    const chatTimeout = setTimeout(() => {
      setIsChatVisible(true);
    }, 12000);

    return () => {
      clearTimeout(chatTimeout);
    };
  }, []);

  const openModal = (feature) => {
    setCurrentFeature(features[feature]);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentFeature(null);
  };

  const handleNavigate = (link) => {
    navigate(link);
    closeModal();
  };

  const handleLoginRedirect = () => {
    navigate('/dashboard');
  };

  const handleConsent = () => {
    setShowConsentModal(false);
  };

  return (
    <div className="home-new-container">
      <section className="intro-section">
        <div className="background-video">
          <video ref={videoRef} autoPlay loop muted>
            <source src="/imagens/eleefon21.mp4" type="video/mp4" />
            Seu navegador não suporta vídeos.
          </video>
        </div>
        <div className="overlay-text">
          <img src="/imagens/logoOficial2024.png" alt="Logo VidaPet.Tech" className="logo" />
          <h1>Cuidar do seu pet nunca foi tão fácil</h1>
          <p>Serviços, facilidades e segurança na palma da mão.</p>
          <button
            className="saiba-mais-btn"
            onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}
          >
            Saiba Mais
          </button>
        </div>
      </section>

      <section className="features-section">
        <h2>Conheça Nossas Ferramentas</h2>
        <div className="features-grid">
          {Object.keys(features).map((feature, index) => (
            <div className="feature-card" key={index}>
              <img
                src={`/imagens/Produto0${index + 1}.jpeg`}
                alt={features[feature].title}
                className="feature-img"
              />
              <h3>{features[feature].title}</h3>
              <button className="saiba-mais-btn" onClick={() => openModal(feature)}>
                Saiba Mais
              </button>
            </div>
          ))}
        </div>
      </section>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={closeModal}>
              &times;
            </button>
            {currentFeature && (
              <>
                <h2>{currentFeature.title}</h2>
                <ul>
                  {currentFeature.description.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <button
                  className="cta-button small-btn"
                  onClick={() => handleNavigate(currentFeature.link)}
                >
                  Conheça Agora
                </button>
              </>
            )}
          </div>
        </div>
      )}

      <section className="blog-section">
        <BlogList />
      </section>

      <section className="cta-final-section">
        <button className="cta-button" onClick={handleLoginRedirect}>
          Cadastre-se Grátis e Comece Agora!
        </button>
      </section>

      <section className="testimonials-section">
        <h2>O Que Nossos Usuários Dizem</h2>
        <div className="testimonials-container">
          <div className="testimonial">
            <p>
              "Fui o primeiro usuário VidaPet.Tech e uso com minha YorkShire, Jade. Tudo está
              guardado lá desde o nascimento dela. Numa emergência, temos todos os dados de saúde
              dela na palma da mão."
            </p>
            <span>- Anderson Santos - Itaboraí - RJ</span>
          </div>
          <div className="testimonial">
            <p>
              "O resgate interativo salvou a vida do meu cachorro, um vizinho do bairro tirou a foto
              dele pelo VidaPet.Tech. Agradeço à equipe!"
            </p>
            <span>- Edson Soares - São Gonçalo - RJ</span>
          </div>
        </div>
      </section>

      {showConsentModal && (
        <div className="modal-overlay consent-modal">
          <div className="modal-content">
            <button className="close-btn" onClick={handleConsent}>
              &times;
            </button>
            <h2>Termos e Política de Privacidade</h2>
<p>
  Ao utilizar o ecossistema VidaPet.Tech, você concorda com nossas políticas descritas em nossa página:    
  <a
    href="https://vidapet.tech/termos-e-privacidade"
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: '#f9652b', textDecoration: 'underline', fontWeight: 'bold' }}
  >
    Termos e Privacidade
  </a>.
</p>
<button className="cta-button" onClick={handleConsent}>
  Aceitar
</button>

          </div>
        </div>
      )}

      {isChatVisible && <UserChat />}
    </div>
  );
};

export default Home;
