import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './UserChat1.css';
import ChatSystemNotifications from './ChatSystemNotifications/ChatSystemNotifications';
import Chatbot from '../../../../components/ChatBot/Chatbot';
import { getUserIdFromFirebaseId } from '../../../Login/userService';
import { auth } from '../../../../firebase';
import liliImage from '../../../../imagens/MascoteLiliVidaPetTech.png'; // Caminho correto da imagem

const UserChat = ({ initialUserId }) => {
  const [userId, setUserId] = useState(initialUserId);
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [message, setMessage] = useState('');
  const [chatMinimized, setChatMinimized] = useState(true);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [firstTimeOpened, setFirstTimeOpened] = useState(true);
  const messagesEndRef = useRef(null);
  const [checkAttempts, setCheckAttempts] = useState(0);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userIdFromApi = await getUserIdFromFirebaseId(user.uid);
          if (userIdFromApi) {
            setUserId(userIdFromApi);
            localStorage.setItem('userId', userIdFromApi);
          }
        } else {
          setUserId('99999');
          localStorage.setItem('userId', '99999');
        }
      } catch (error) {
        console.error('Erro ao verificar o status de login:', error);
      }
    };
    
    checkLoginStatus();

    const interval = setInterval(() => {
      if (checkAttempts < 12 && userId === null) {
        checkLoginStatus();
        setCheckAttempts(prev => prev + 1);
      } else {
        clearInterval(interval);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [checkAttempts, userId]);

  useEffect(() => {
    const defaultLili = {
      partnerId: '9999',
      displayName: 'Lili',
      photoURL: liliImage,
      email: 'lili@vidapet.tech',
    };

    const fetchPartners = async () => {
      try {
        if (userId) {
          const response = await axios.get(`https://vidapet.tech/api/DB/vetclinic_db/api/system/get_chat_partners.php?userId=${userId}`);
          if (Array.isArray(response.data)) {
            const partnersList = response.data.filter(partner => partner.displayName !== 'Lili');
            setPartners(partnersList);
            
            const liliPartner = defaultLili;
            if (liliPartner) {
              selectPartner(liliPartner);
            }
          }
        } else {
          setPartners([]);
          const liliPartner = defaultLili;
          if (liliPartner) {
            selectPartner(liliPartner);
          }
        }
      } catch (error) {
        console.error('Erro ao buscar parceiros de chat:', error);
      }
    };

    fetchPartners();
  }, [userId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const chatContainer = document.querySelector('.chat-wrapper');
      const notificationsContainer = document.querySelector('.notifications-section');
      
      if (chatContainer && !chatContainer.contains(event.target) && 
          notificationsContainer && !notificationsContainer.contains(event.target)) {
        setChatMinimized(true);
        setSidebarVisible(true);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  const selectPartner = async (partner) => {
    setSelectedPartner(partner);
    if (partner.partnerId === '9999') {
      const initialMessages = [
        { senderId: '9999', text: "Bem-vindo(a), me chamo Lili e sou a Nuvenzinha de Inteligência Artificial da VidaPet.Tech. Estou aqui para tirar suas dúvidas" },
        { senderId: '9999', text: "Você já conhece o VidaPet.Tech?" }
      ];
      setChatHistory(initialMessages);
    } else {
      try {
        const response = await axios.get(`https://vidapet.tech/api/DB/vetclinic_db/api/system/get_chat_history.php?senderId=${userId}&recipientId=${partner.partnerId}`);
        const chatHistoryData = response.data || [];
  
        const validChatHistory = chatHistoryData
          .filter(msg => msg && typeof msg.message === 'string' && msg.message.trim() !== '')
          .map(msg => ({
            senderId: msg.senderId,
            text: msg.message,
            timestamp: msg.timestamp
          }));
  
        setChatHistory(validChatHistory);
      } catch (error) {
        console.error('Erro ao buscar histórico de chat:', error);
        setChatHistory([]);
      }
    }
  };

  useEffect(() => {
    if (!firstTimeOpened && selectedPartner?.partnerId === '9999') {
      setChatMinimized(false);
      setSidebarVisible(true);
      setFirstTimeOpened(true);
    }
  }, [selectedPartner, firstTimeOpened]);

  const handleChatbotMessages = async (userMessage) => {
    const updatedMessages = [...chatHistory, userMessage];
    setChatHistory(updatedMessages);

    const botResponse = await Chatbot({ userMessage: userMessage.text, chatHistory: updatedMessages });
    setChatHistory(prevHistory => [...prevHistory, botResponse]);

    if (userId) {
      await axios.post('https://vidapet.tech/api/DB/vetclinic_db/api/system/send_message.php', {
        chatId: '9999',
        senderId: userId,
        recipientId: '9999',
        message: userMessage.text,
      });

      await axios.post('https://vidapet.tech/api/DB/vetclinic_db/api/system/send_message.php', {
        chatId: '9999',
        senderId: '9999',
        recipientId: userId,
        message: botResponse.text,
      });
    }
  };

  const sendMessage = () => {
    if (!userId && selectedPartner.partnerId !== '9999') {
      console.error('Erro: userId não está definido');
      return;
    }
  
    if (message.trim() && selectedPartner) {
      const newMessage = {
        senderId: userId || '9999',
        text: message.trim(),
      };
  
      if (selectedPartner.partnerId === '9999') {
        handleChatbotMessages(newMessage);
      } else {
        const updatedChatHistory = [...chatHistory, newMessage];
        setChatHistory(updatedChatHistory);
  
        setMessage('');
  
        axios.post('https://vidapet.tech/api/DB/vetclinic_db/api/system/send_message.php', {
          chatId: selectedPartner.partnerId,
          senderId: userId,
          recipientId: selectedPartner.partnerId,
          message: newMessage.text,
        }).catch((error) => {
          console.error('Erro ao enviar a mensagem:', error);
        });
      }
  
      setMessage('');
    }
  };

  const handleNotificationSelect = (notification) => {
    const lili = {
      partnerId: '9999',
      displayName: 'Lili',
      photoURL: liliImage,
      email: 'lili@vidapet.tech',
    };

    selectPartner(lili);

    setChatHistory(prevHistory => [
      ...prevHistory,
      { senderId: '9999', text: notification.message }
    ]);
  };

  const handleLiliClick = () => {
    const lili = {
      partnerId: '9999',
      displayName: 'Lili',
      photoURL: liliImage,
      email: 'lili@vidapet.tech',
    };

    selectPartner(lili);

    setChatHistory(prevHistory => [
      ...prevHistory,
      { senderId: '9999', text: "Como posso ajudar?" }
    ]);
  };

  useEffect(() => {
    if (!userId) {
      const defaultLili = {
        partnerId: '9999',
        displayName: 'Lili',
        photoURL: liliImage,
        email: 'lili@vidapet.tech',
      };
      selectPartner(defaultLili);
    }
  }, [userId]);

  const toggleChatMinimized = (e) => {
    e.stopPropagation();
    setChatMinimized(prevState => !prevState);
    setSidebarVisible(prevState => !prevState);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };
  
  return (
    <div className={`home-container ${!sidebarVisible ? 'no-sidebar' : ''}`}>
      <div className="home-content">
        {/* Conteúdo adicional da página */}
      </div>
      <div className={`chat-wrapper ${chatMinimized ? 'minimized' : ''}`} onClick={chatMinimized ? toggleChatMinimized : null}>
        {chatMinimized && (
          <img 
            src={liliImage} 
            alt="Lili Mascote" 
            className="minimized-chat-icon" 
            onClick={toggleChatMinimized}
          />
        )}
        <button className="minimize-btn" onClick={toggleChatMinimized}>
          {chatMinimized ? '' : 'Minimizar'}
        </button>
        <div className="chat-container">
          <div className="chat-content">
            {!chatMinimized && selectedPartner && (
              <div className="chat-window">
                <div className="chat-header">
                  <img src={selectedPartner.photoURL} alt={selectedPartner.displayName} />
                  <h4>{selectedPartner.displayName}</h4>
                </div>
                <div className="chat-messages">
                  {chatHistory.map((msg, index) => (
                    <div key={index} className={`message ${msg.senderId === userId ? 'sent' : 'received'}`}>
                      <p>{msg.text}</p>
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>
                <div className="chat-input">
                  <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleKeyPress}
                    placeholder="Digite sua mensagem..."
                  />
                  <button className="chat-button" onClick={sendMessage}>Enviar</button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`notifications-section ${chatMinimized ? 'minimized' : ''}`}>
        <ChatSystemNotifications onNotificationSelect={handleNotificationSelect} />
      </div>
    </div>
  );
};

export default UserChat;