import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { IoMdCloseCircle, IoMdHeart, IoMdPrint, IoMdPeople } from 'react-icons/io';
import { FaEdit, FaSave } from 'react-icons/fa';
import HealthEventsModal from './HealthEventsModal';
import VaccineModal from './VaccineModal';
import PrintCarteirinhaPdf from './PrintCarteirinhaPdf';
import { QRCodeCanvas } from 'qrcode.react';
import AdoptMe from './AdoptMe';
import petIcon from '../../../imagens/MascoteLiliVidaPetTech.png';

const Carteirinha = ({ pet, onClose }) => {
  const [nomeTutor, setNomeTutor] = useState('');
  const [telefoneTutor, setTelefoneTutor] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [showHealthEventsModal, setShowHealthEventsModal] = useState(false);
  const [showVaccineModal, setShowVaccineModal] = useState(false);
  const [selectedVaccine, setSelectedVaccine] = useState(null);
  const [appliedVaccines, setAppliedVaccines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showAdoptMeModal, setShowAdoptMeModal] = useState(false);
  const [isInAdoptionProcess, setIsInAdoptionProcess] = useState(false);
  const [petImage, setPetImage] = useState(
    pet.pet_3x4 ? `https://vidapet.tech/vidapet/pets/${pet.pet_3x4.split('/').pop()}` : petIcon
  );

  const qrCodeRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://vidapet.tech/api/db/vidapettech/adoption/adoption_user_pet.php?petId=${pet.petId || pet.id}`);
        if (!response.ok) throw new Error('Erro ao buscar status de adoção');
        const responseData = await response.json();
        setIsInAdoptionProcess(responseData.isInAdoption); // Verifica se o pet está em adoção

        // Buscando informações da carteirinha
        const carteirinhaResponse = await fetch(`https://vidapet.tech/api_carteira_pet.php?petId=${pet.petId || pet.id}`);
        const carteirinhaData = await carteirinhaResponse.json();
        setNomeTutor(carteirinhaData.observacao01 || 'Tutor');
        setTelefoneTutor(carteirinhaData.observacao02 || 'Telefone');

        const vaccinesResponse = await fetch(`https://vidapet.tech/vaccines.php?petId=${pet.petId}`);
        const vaccinesData = await vaccinesResponse.json();
        setAppliedVaccines(vaccinesData);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };
    fetchData();
  }, [pet]);

  const handleSaveTutorInfo = async () => {
    try {
      const response = await fetch('https://vidapet.tech/api_carteira_pet.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          petId: pet.petId || pet.id,
          nomePet: pet.petName,
          tipo: pet.Type || "",
          raca: pet.breed || "",
          tutorUserId: pet.userId || "",
          observacao01: nomeTutor,
          observacao02: telefoneTutor,
          observacao03: ""
        }),
      });

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(`Erro da API: ${responseData.error}`);
      }

      alert('Informações salvas com sucesso!');
      
      const updatedResponse = await fetch(`https://vidapet.tech/api_carteira_pet.php?petId=${pet.petId || pet.id}`);
      const updatedData = await updatedResponse.json();

      setNomeTutor(updatedData.observacao01 || 'Tutor');
      setTelefoneTutor(updatedData.observacao02 || 'Telefone');
      setEditMode(false);
    } catch (error) {
      console.error('Erro ao salvar informações do tutor:', error);
    }
  };

  const handleCancelAdoption = async () => {
    if (window.confirm("Tem certeza de que deseja cancelar a adoção?")) {
      try {
        console.log("Iniciando o processo de cancelamento de adoção...");

        const response = await fetch('https://vidapet.tech/api/db/vidapettech/adoption/adoption_user_pet.php', {
          method: 'POST',  // Alterado para POST
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ petId: pet.petId || pet.id, action: 'delete' })  // Adiciona "action"
        });

        console.log("Resposta da API:", response);
        const responseData = await response.json();
        console.log("Dados recebidos da API:", responseData);

        if (!response.ok || !responseData.success) {
          console.error("Erro ao cancelar adoção:", responseData.message);
          throw new Error(responseData.message || 'Erro ao cancelar adoção');
        }

        alert('Adoção cancelada com sucesso');
        setIsInAdoptionProcess(false); // Atualizar o estado após o cancelamento
      } catch (error) {
        console.error('Erro ao cancelar adoção:', error);
        alert('Erro ao cancelar adoção');
      }
    }
};


  const handleVaccineClick = (vaccine) => {
    setSelectedVaccine(vaccine);
    setShowVaccineModal(true);
  };

  return (
    <ModalBackground>
      <ModalContent>
        <Header>
          <LogoContainer>
            <Logo src={petIcon} alt="Vida Pet" />
            <Title>VidaPet.Tech</Title>
          </LogoContainer>
          <CloseButton onClick={onClose} />
        </Header>

        <MainContent>
          <PetInfoSection>
            <PetPhotoContainer>
              <PetPhoto src={petImage} alt={pet.petName} />
              <PetName>{pet.petName}</PetName>
            </PetPhotoContainer>
            
            <InfoGrid>
              <InfoItem>
                <InfoLabel>Raça</InfoLabel>
                <InfoValue>{pet.breed}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Idade</InfoLabel>
                <InfoValue>{pet.age} anos</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Tutor</InfoLabel>
                {editMode ? (
                  <Input
                    type="text"
                    value={nomeTutor}
                    onChange={(e) => setNomeTutor(e.target.value)}
                  />
                ) : (
                  <InfoValue>{nomeTutor}</InfoValue>
                )}
              </InfoItem>
              <InfoItem>
                <InfoLabel>Telefone</InfoLabel>
                {editMode ? (
                  <Input
                    type="text"
                    value={telefoneTutor}
                    onChange={(e) => setTelefoneTutor(e.target.value)}
                  />
                ) : (
                  <InfoValue>{telefoneTutor}</InfoValue>
                )}
              </InfoItem>
            </InfoGrid>

            <EditButton 
              onClick={editMode ? handleSaveTutorInfo : () => setEditMode(true)}
            >
              {editMode ? <FaSave /> : <FaEdit />}
              {editMode ? 'Salvar' : 'Editar'}
            </EditButton>
          </PetInfoSection>

          <VaccineSection>
            <QRCodeContainer ref={qrCodeRef}>
              <QRCodeCanvas 
                value={`https://vidapet.tech/resgate?qrcode=${pet.petId}`} 
                size={100}
              />
            </QRCodeContainer>
          </VaccineSection>
        </MainContent>

        <ActionButtonsContainer>
          <ActionButton onClick={() => setShowHealthEventsModal(true)}>
            <IoMdHeart />
            <span>Eventos de Saúde</span>
          </ActionButton>
          <ActionButton onClick={() => setShowPrintModal(true)}>
            <IoMdPrint />
            <span>Carteirinha PDF</span>
          </ActionButton>
          {isInAdoptionProcess ? (
            <ActionButton1 onClick={handleCancelAdoption}>
              <IoMdPeople />
              <span>Cancelar Adoção</span>
            </ActionButton1>
          ) : (
            <ActionButton1 onClick={() => setShowAdoptMeModal(true)}>
              <IoMdPeople />
              <span>Doação</span>
            </ActionButton1>
          )}
        </ActionButtonsContainer>

        {showHealthEventsModal && (
          <HealthEventsModal
            petId={pet.petId}
            onClose={() => setShowHealthEventsModal(false)}
          />
        )}
        
        {showVaccineModal && (
          <VaccineModal
            vaccine={selectedVaccine}
            petId={pet.petId}
            onClose={() => setShowVaccineModal(false)}
          />
        )}

        {showPrintModal && (
          <PrintCarteirinhaPdf
            pet={pet}
            onClose={() => setShowPrintModal(false)}
          />
        )}

        {showAdoptMeModal && (
          <AdoptMe
            pet={pet}
            onClose={() => setShowAdoptMeModal(false)}
          />
        )}

        {loading && <LoadingMessage>Gerando PDF...</LoadingMessage>}
      </ModalContent>
    </ModalBackground>
  );
};


// Estilos melhorados
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 20px;
  width: 95%;
  max-width: 500px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-height: 90vh;
  overflow-y: auto;

  @media (min-width: 768px) {
    width: 90%;
    max-width: 600px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #333;
  font-weight: 600;
`;

const CloseButton = styled(IoMdCloseCircle)`
  font-size: 24px;
  color: #666;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: #333;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const PetInfoSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const PetPhotoContainer = styled.div`
  position: relative;
  text-align: center;
`;

const PetPhoto = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 60px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const PetName = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
  color: #333;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
`;

const InfoItem = styled.div`
  background-color: #f5f7fa;
  padding: 10px;
  border-radius: 8px;
`;

const InfoLabel = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 14px;
  color: #333;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;
const EditButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }

  & svg {
    margin-right: 5px;
  }
`;

const VaccineSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

const VaccinesContainer = styled.div`
  background-color: #f5f7fa;
  border-radius: 8px;
  padding: 15px;
`;

const VaccineHeader = styled.h4`
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
`;

const VaccineGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;

const VaccineIcon = styled.div`
  background-color: #e74c3c;
  color: white;
  border-radius: 6px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #c0392b;
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ActionButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #f5f7fa;
  border: none;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;

  svg {
    font-size: 20px;
    color: #007bff;
  }

  span {
    font-size: 12px;
    color: #333;
  }

  &:hover {
    background-color: #e8eaed;
  }
`;

const ActionButton1 = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #ff9800;
  border: none;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;

  svg {
    font-size: 20px;
    color: #007bff;
  }

  span {
    font-size: 12px;
    color: #333;
  }

  &:hover {
    background-color: #e8eaed;
  }
`;


const LoadingMessage = styled.p`
  text-align: center;
  color: #666;
  margin-top: 10px;
`;

export default Carteirinha;