import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const HealthEventsView = ({ petId, reloadEvents, viewOnly = false, gridView = false }) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingEvent, setEditingEvent] = useState(null);
  const [formData, setFormData] = useState({
    event_type: '',
    event_description: '',
    event_date: '',
    anexo_photo: null,
  });

  // Função que carrega os eventos de saúde
  const fetchHealthEvents = async () => {
    try {
      const response = await axios.get(`https://vidapet.tech/get_health_events.php?petId=${petId}`);
      if (Array.isArray(response.data)) {
        setEvents(response.data);
      } else {
        setEvents([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Erro ao carregar eventos de saúde:', error);
      setIsLoading(false);
    }
  };

  // useEffect para carregar os eventos de saúde e quando petId ou reloadEvents mudarem
  useEffect(() => {
    fetchHealthEvents();
  }, [petId]);

  // Função de recarga dos eventos
  const handleReloadEvents = () => {
    // Verifica se a prop reloadEvents foi passada e é uma função
    if (reloadEvents && typeof reloadEvents === 'function') {
      reloadEvents();
    } else {
      // Caso contrário, recarrega os eventos localmente
      fetchHealthEvents();
    }
  };

  const handleEditEvent = (event) => {
    setEditingEvent(event);
    setFormData({
      event_type: event.event_type,
      event_description: event.event_description,
      event_date: event.event_date,
      anexo_photo: event.anexo_photo || null,
    });
  };

  const handleCancelEdit = () => {
    setEditingEvent(null);
    setFormData({
      event_type: '',
      event_description: '',
      event_date: '',
      anexo_photo: null,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, anexo_photo: file || null });
  };

  const handleSaveEvent = async () => {
    const { event_type, event_description, event_date, anexo_photo } = formData;
  
    // Validação simples da data
    const formattedDate = new Date(event_date);
    if (isNaN(formattedDate)) {
      console.error("Data inválida");
      return;
    }
  
    const updatedEvent = {
      action: "updateEvent",  // Ação para atualizar evento
      id: editingEvent.id,  // ID do evento que está sendo editado
      event_type,  // Tipo de evento
      event_description,  // Descrição do evento
      event_date: formattedDate.toISOString().split('T')[0],  // Data formatada (somente a data)
      anexo_photo: anexo_photo ? anexo_photo.name : null,  // Nome do arquivo anexado, ou null se não houver
    };
  
    try {
      const response = await axios.put(`https://vidapet.tech/CRUD-Health-Events.php?eventId=${editingEvent.id}`, updatedEvent);
      // Recarregar eventos após salvar
      handleReloadEvents();
      setEditingEvent(null);
      setFormData({
        event_type: '',
        event_description: '',
        event_date: '',
        anexo_photo: null,
      });
    } catch (error) {
      console.error('Erro ao salvar evento:', error);
    }
  };

  const handleDeleteHealthEvent = async (eventId) => {
    try {
      const confirmDelete = window.confirm('Você realmente deseja excluir este evento de saúde?');
      
      if (confirmDelete) {
        await axios.delete(`https://vidapet.tech/CRUD-Health-Events.php?id=${eventId}`);
  
        // Recarregar eventos após exclusão
        handleReloadEvents();
      }
    } catch (error) {
      console.error('Erro ao excluir evento de saúde:', error);
    }
  };

  const renderAttachmentThumbnail = (attachmentPath) => {
    if (!attachmentPath) return null;
    
    const fileUrl = `https://vidapet.tech/${attachmentPath}`;
    const extension = attachmentPath.split('.').pop().toLowerCase();
    
    if (['jpg', 'jpeg', 'png'].includes(extension)) {
      return (
        <a href={fileUrl} download>
          <img src={fileUrl} alt="Anexo" style={{ maxWidth: '100px', cursor: 'pointer' }} />
        </a>
      );
    } else if (extension === 'pdf') {
      return (
        <a href={fileUrl} download>
          <img src="pdf-icon.png" alt="PDF Icon" style={{ maxWidth: '100px', cursor: 'pointer' }} />
        </a>
      );
    } else {
      return (
        <a href={fileUrl} download>
          <span>Arquivo: {attachmentPath}</span>
        </a>
      );
    }
  };

  const eventTypeOptions = ['Consulta', 'Vacinação', 'Exame', 'Cirurgia', 'Outro'];

  return (
    <Container gridView={gridView}>
      <h3>Eventos de Saúde</h3>
      {isLoading ? (
        <p>Carregando eventos...</p>
      ) : events && events.length > 0 ? (
        <EventsGrid>
          {events.map((event) => (
            <EventCard key={event.id}>
              {editingEvent === event ? (
                <>
                  <div>
                    <label>Tipo de Evento:</label>
                    <select
                      name="event_type"
                      value={formData.event_type}
                      onChange={handleInputChange}
                    >
                      <option value="">Selecione o tipo</option>
                      {eventTypeOptions.map((option) => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Descrição:</label>
                    <textarea
                      name="event_description"
                      value={formData.event_description}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label>Data:</label>
                    <input
                      type="date"
                      name="event_date"
                      value={formData.event_date}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label>Anexar Foto:</label>
                    <input
                      type="file"
                      accept="image/*,application/pdf"
                      onChange={handleFileChange}
                    />
                  </div>
                  <button onClick={handleSaveEvent}>Salvar</button>
                  <button onClick={handleCancelEdit}>Cancelar</button>
                </>
              ) : (
                <>
                  <div><strong>Tipo:</strong> {event.event_type}</div>
                  <div><strong>Descrição:</strong> {event.event_description}</div>
                  <div><strong>Data:</strong> {event.event_date}</div>
                  <div>{renderAttachmentThumbnail(event.anexo_photo)}</div>
                  {!viewOnly && (
                    <>
                      <button onClick={() => handleEditEvent(event)}>Editar</button>
                      <button onClick={() => handleDeleteHealthEvent(event.id)}>Excluir</button>
                    </>
                  )}
                </>
              )}
            </EventCard>
          ))}
        </EventsGrid>
      ) : (
        <p>Nenhum evento de saúde encontrado.</p>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: ${(props) => (props.gridView ? 'grid' : 'block')};
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
`;

const EventsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
`;

const EventCard = styled.div`
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

export default HealthEventsView;
