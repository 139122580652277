import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const GamificationPoints = () => {
  const [points, setPoints] = useState(0);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    // Fetch gamification points and activities from the backend
    axios.get('/api/gamification')
      .then(response => {
        setPoints(response.data.points);
        setActivities(response.data.activities);
      })
      .catch(error => console.error("Error fetching gamification points:", error));
  }, []);

  return (
    <Container>
      <Header>
        <Points>{points}</Points>
        <PointsLabel>Pontos</PointsLabel>
      </Header>
      <ActivitiesList>
        {activities.map(activity => (
          <ActivityItem key={activity.id}>
            <ActivityDescription>{activity.description}</ActivityDescription>
            <ActivityPoints>{activity.points} pts</ActivityPoints>
          </ActivityItem>
        ))}
      </ActivitiesList>
    </Container>
  );
};

export default GamificationPoints;

// Estilos
const Container = styled.div`
  background: #ffffff;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 150px;
  margin: 10px auto;
  font-family: 'Arial', sans-serif;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 12px;
`;

const Points = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #2a9d8f;
  display: block;
`;

const PointsLabel = styled.span`
  font-size: 14px;
  color: #7a7a7a;
`;

const ActivitiesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ActivityItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  padding: 8px 12px;
  margin: 6px 0;
  border-radius: 6px;
  font-size: 12px;
  color: #555;
`;

const ActivityDescription = styled.span`
  font-weight: 200;
  color: #333;
`;

const ActivityPoints = styled.span`
  font-weight: 200;
  color: #e76f51;
`;
