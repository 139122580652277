import React from 'react';
import './TelemedicineVet.css';
import heroImage from '../../imagens/TeleVetHero.webp';

const TelemedicineVet = () => {
  const handleWhatsAppClick = () => {
    const message = encodeURIComponent(
      `Gostaria de saber mais sobre como cadastrar minha clínica no sistema VidaPet.Tech para Teleconsulta Veterinária:\n` +
      `- Nome da Clínica:\n` +
      `- Nome do Veterinário Responsável:\n` +
      `- Cidade/Estado:\n` +
      `- Contato WhatsApp:`
    );
    window.open(`https://wa.me/5521982992635?text=${message}`, '_blank');
  };

  return (
    <div className="telemedicine-vet-container">
      {/* Hero Section */}
      <section className="hero-section">
        <h1>Teleconsulta para Clínicas e Veterinários</h1>
        <p>
          Amplie sua área de atuação e fidelize seus clientes com o sistema de Teleconsulta VidaPet.Tech. 
          Conecte seu consultório e torne-o mais acessível!
        </p>
        <img src={heroImage} alt="Telemedicina Veterinária" className="hero-image" />

        <button className="whatsapp-button" onClick={handleWhatsAppClick}>
          Entre em Contato com Nossa Equipe via WhatsApp
        </button>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section">
        <h2>Benefícios para a Sua Atividade</h2>
        <ul>
          <li>
            <span className="check">✔</span> Uso gratuito do plano básico, com uma Sala de Teleconsulta Veterinária à sua disposição.
          </li>
          <li>
            <span className="check">✔</span> Cada paciente cadastrado pelo seu link será automaticamente associado à sua clínica, garantindo fidelização.
          </li>
          <li>
            <span className="check">✔</span> Descontamos nossa taxa de serviço apenas quando o paciente realiza o pagamento via Pix QR Code na plataforma.
          </li>
          <li>
            <span className="check">✔</span> Extensão do consultório físico para atendimentos online, oferecendo mais conveniência aos seus clientes.
          </li>
        </ul>
      </section>

      {/* Call-to-Action Section */}
      <section className="cta-section">
        <h2>Comece Agora!</h2>
        <p>
          Solicite o cadastro do seu consultório ou clínica e amplie sua área de atendimento. Nossa equipe está
          disponível para atendê-lo de segunda a domingo, das 8h às 22h.
        </p>
        <button className="whatsapp-button" onClick={handleWhatsAppClick}>
          Fale com Nossa Equipe via WhatsApp
        </button>
      </section>

      <footer>
        <p>VidaPet.Tech - Todos os direitos reservados.</p>
      </footer>
    </div>
  );
};

export default TelemedicineVet;
