import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ServicoDetalhes.css';
import Gallery from './Gallery';

const ServiceDetails = () => {
  const { id } = useParams();
  
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(true);  
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await fetch(`https://vidapet.tech/servicos.php?id=${id}`);
        if (!response.ok) throw new Error('Erro na resposta da rede');
        
        const data = await response.json();
        const selectedService = data.find(s => s.id === id);
        
        if (selectedService) {
          setService(selectedService);
        } else {
          setError('Serviço não encontrado.');
        }
      } catch (error) {
        setError('Erro ao buscar os dados do serviço. Tente novamente mais tarde.');
      } finally {
        setLoading(false);
      }
    };
    fetchServiceDetails();
  }, [id]);

  const shareOnWhatsApp = () => {
    if (!service) return;
    const url = window.location.href;
    const message = `Olá! Encontrei um serviço que pode ser útil:\n\n${service.nome}\n${url}\n\nVisite para mais informações!`;
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank');
  };

  const handleShareOnFacebook = () => {
    const url = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
  };

  const handleBackClick = () => {
    // Redireciona diretamente para a URL especificada
    window.location.href = 'https://vidapet.tech/servico';
  };

  if (loading) {
    return (
      <div className="state-container">
        <p>Carregando...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="state-container">
        <p>{error}</p>
        <button className="back-btn" onClick={handleBackClick}>← Voltar</button>
      </div>
    );
  }

  if (!service) {
    return (
      <div className="state-container">
        <p>Serviço não encontrado.</p>
        <button className="back-btn" onClick={handleBackClick}>← Voltar</button>
      </div>
    );
  }

  return (
    <div className="container-service">
      <header className="header-service">
        <button className="back-btn" onClick={handleBackClick}>← Voltar</button>
        <h1 className="service-title">{service.nome}</h1>
      </header>

      <section className="content-wrapper">
        <div className="image-container">
          <img src={service.logo_url} alt={`Serviço ${service.nome}`} />
        </div>
        <div className="details-section">
          <h2>Responsável: {service.nome_responsavel}</h2>
          <p className="description">{service.descricao}</p>
          <div className="info-block">
            <p><strong>Endereço:</strong> {service.endereco_comercial}</p>
            <p><strong>Cidade:</strong> {service.cidade}</p>
            <p><strong>Bairro:</strong> {service.bairro}</p>
          </div>
          <p><strong>WhatsApp:</strong> <a className="link" href={`https://wa.me/${service.whatsapp}`} target="_blank" rel="noopener noreferrer">{service.whatsapp}</a></p>
          <p><strong>Site:</strong> <a className="link" href={service.site} target="_blank" rel="noopener noreferrer">{service.site}</a></p>
          <div className="share-buttons">
            <button className="btn-whatsapp" onClick={shareOnWhatsApp}>Compartilhar no WhatsApp</button>
            <button className="btn-facebook" onClick={handleShareOnFacebook}>Compartilhar no Facebook</button>
          </div>
        </div>
      </section>

      <section className="gallery-section">
        <h2>Minha Galeria de Fotos</h2>
        <Gallery serviceId={service.id} />
      </section>
    </div>
  );
};

export default ServiceDetails;
