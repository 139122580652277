import React, { useEffect, useState } from 'react';
import './Components/Cartalogo.css'; 
import Filtro from './Components/FilterServicos';
import { Link } from 'react-router-dom';
import ServiceMap from './Components/ServiceMap'; // Importando o componente do mapa

function Servicos() {
  const [partners, setPartners] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [bairro, setBairro] = useState('');
  const [categoria, setCategoria] = useState('');
  const [showMap, setShowMap] = useState(false); // Estado para controlar a exibição do mapa

  useEffect(() => {
    fetchPartners();
  }, [bairro, categoria]);

  const fetchPartners = () => {
    let url = 'https://vidapet.tech/parceiros_mkt.php';
    const params = [];

    if (bairro) params.push(`bairro=${bairro}`);
    if (categoria) params.push(`categoria=${categoria}`);

    if (params.length > 0) {
      url += '?' + params.join('&');
    }

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setPartners(data);
        } else {
          console.error('Unexpected data format:', data);
        }
      })
      .catch(error => console.error('Error fetching partners:', error));
  };

  const filteredPartners = partners.filter(partner =>
    partner.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="servicos-container">
      <Filtro 
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        bairro={bairro}
        setBairro={setBairro}
        categoria={categoria}
        setCategoria={setCategoria}
      />

      {/* Botão para exibir o mapa */}
      <button onClick={() => setShowMap(prev => !prev)}>
        {showMap ? 'Ocultar Mapa' : 'Mostrar Mapa'}
      </button>
      {showMap && <ServiceMap />} {/* Renderiza o mapa se showMap for true */}

     
      <div className="servicos-grid">
        {filteredPartners.length > 0 ? (
          filteredPartners.map(partner => (
            <div className="servicos-item" key={partner.id}>
              <Link to={`/servico/${partner.id}`}>
                <img src={partner.logo_url} alt={partner.nome_fantasia} />
                <h3>{partner.nome_fantasia}</h3>
                <p><strong>Endereço:</strong> {partner.endereco_comercial} {partner.bairro}</p>
                <p><strong>WhatsApp:</strong> {partner.whatsapp}</p>
                {partner.categoria}
              </Link>
              <div className="partner-links">
                <a href={`/servico/${partner.id}`} target="_blank" rel="noopener noreferrer" className='FollowButton'>Saiba Mais</a>
              </div>
            </div>
          ))
        ) : (
          <p>Nenhum parceiro encontrado</p>
        )}
      </div>
    </div>
  );
}

export default Servicos;
