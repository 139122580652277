import React, { useState, useRef } from 'react';

/**
 * Estilos personalizados para o modal de licença
 * A definição dos estilos é modular, garantindo a flexibilidade e o controle total sobre a aparência do modal.
 * 
 * @constant modalStyle
 */
const modalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    background: 'white',
    padding: '20px',
    maxWidth: '500px',
    width: '100%',
    borderRadius: '8px',
    position: 'relative',
    overflowY: 'auto',
    maxHeight: '70vh',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.6',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  acceptButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
  },
  closeButtonStyle: {
    backgroundColor: '#f44336',
    color: 'white',
  },
};

/**
 * Componente `LicenseModal`
 * Exibe um modal interativo que apresenta a licença de uso do serviço ou aplicativo, permitindo ao usuário ler os termos e condições e, em seguida, aceitá-los ou fechá-los.
 *
 * Este modal é essencial para garantir a conformidade legal do usuário com os termos e condições da plataforma antes de acessar os serviços.
 * 
 * @component
 * 
 * @param {string} userId - Identificador único do usuário, usado para registrar a aceitação da licença no backend.
 * @param {function} onClose - Função de callback responsável por fechar o modal quando o usuário clicar no botão "Fechar" ou "Aceitar".
 * 
 * @returns {JSX.Element} O modal que exibe a licença de uso.
 */
function LicenseModal({ userId, onClose }) {
  // Estado que controla a habilitação do botão de aceitação.
  const [isEnabled, setIsEnabled] = useState(false);

  // Referência ao final do conteúdo da licença, para monitoramento do scroll.
  const endOfLicenseRef = useRef(null);

  /**
   * Função `handleAcceptLicense`
   * Registra o aceite da licença pelo usuário, enviando um pedido POST ao servidor para registrar essa ação no banco de dados.
   * Após o sucesso, o modal é fechado automaticamente.
   */
  const handleAcceptLicense = () => {
    fetch('https://vidapet.tech/api/DB/vidapettech/licenca.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        console.log('Licença aceita com sucesso.');
        onClose(); // Fecha o modal após o aceite
      } else {
        console.error('Erro ao aceitar licença:', data.error);
      }
    })
    .catch(error => {
      console.error('Erro na rede:', error);
    });
  };

  /**
   * Função `handleScroll`
   * Controla o comportamento do botão "Aceitar". Ele só se torna habilitado após o usuário rolar até o final da licença.
   * 
   * @param {Object} event - O evento de scroll.
   */
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollTop + clientHeight === scrollHeight) {
      setIsEnabled(true); // Habilita o botão "Aceitar" após o final do scroll
    }
  };

  return (
    <div style={modalStyle.overlay}>
      <div style={modalStyle.content} onScroll={handleScroll}>
        {/* Botão de Fechar Modal */}
        <button style={modalStyle.closeButton} onClick={onClose}>X</button>
        
        {/* Cabeçalho do Modal */}
        <h2 style={modalStyle.header}>Licença de Uso - VidaPet.Tech</h2>
        
        {/* Introdução à Licença */}
        <p style={modalStyle.paragraph}>
          Ao acessar ou utilizar a plataforma VidaPet.Tech, você concorda com os seguintes termos e condições de uso.
        </p>

        {/* Seções da Licença */}
        <h3 style={modalStyle.header}>1. Uso Permitido</h3>
        <p style={modalStyle.paragraph}>
          Você tem permissão para utilizar os serviços da VidaPet.Tech de acordo com os termos descritos nesta licença.
        </p>

        <h3 style={modalStyle.header}>2. Restrições de Uso</h3>
        <p style={modalStyle.paragraph}>
          Você não pode modificar, distribuir ou vender o conteúdo da plataforma sem permissão expressa.
        </p>

        <h3 style={modalStyle.header}>3. Responsabilidade</h3>
        <p style={modalStyle.paragraph}>
          Você é responsável pelo uso adequado da plataforma e deve seguir as diretrizes de segurança.
        </p>

        <h3 style={modalStyle.header}>4. Alterações na Licença</h3>
        <p style={modalStyle.paragraph}>
          A VidaPet.Tech pode alterar os termos desta licença a qualquer momento. Alterações serão publicadas nesta página.
        </p>

        <h3 style={modalStyle.header}>5. Direitos Autorais</h3>
        <p style={modalStyle.paragraph}>
          Todos os direitos autorais e propriedade intelectual relacionados à plataforma pertencem à VidaPet.Tech.
        </p>

        <h3 style={modalStyle.header}>6. Encerramento</h3>
        <p style={modalStyle.paragraph}>
          A violação de qualquer termo desta licença pode resultar no encerramento da sua conta na plataforma.
        </p>

        <h3 style={modalStyle.header}>7. Contato</h3>
        <p style={modalStyle.paragraph}>
          Se você tiver dúvidas sobre os termos desta licença, entre em contato conosco em contato@vidapet.tech.
        </p>

        {/* Referência ao final do conteúdo da licença */}
        <div ref={endOfLicenseRef}></div>

        {/* Contêiner de Botões */}
        <div style={modalStyle.buttonContainer}>
          {/* Botão de Aceitar */}
          <button
            style={{ ...modalStyle.button, ...modalStyle.acceptButton }}
            onClick={handleAcceptLicense}
            disabled={!isEnabled} // Desabilita o botão até que o final da licença seja alcançado
          >
            Aceitar
          </button>
          {/* Botão de Fechar Modal */}
          <button
            style={{ ...modalStyle.button, ...modalStyle.closeButtonStyle }}
            onClick={onClose}
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
}

export default LicenseModal;
