import React from 'react';
import petListImg from '../../imagens/petlist.png';
import carteirinhaImg from '../../imagens/carteirinha1.png';
import vacinacoesImg from '../../imagens/vacinacoes1.png';
import consultasImg from '../../imagens/consultas1.png';
import examesImg from '../../imagens/exames1.png';
import qrCodeImg from '../../imagens/qrcode1.png';

const DashboardHome = ({ onAccess }) => {
  return (
    <div style={styles.dashboardHome}>
      <h2 style={styles.warningTitle}>🐾 Proteja Seu Pet com Vida Pet Tech</h2>
      <p style={styles.paragraph}>
        Descubra como podemos simplificar o cuidado com seu melhor amigo e prevenir problemas futuros:
      </p>

      <div style={styles.infoSection}>
        {painPointItems.map((item, index) => (
          <div key={index} style={styles.infoItem}>
            <img src={item.img} alt={item.alt} style={styles.infoImage} />
            <div style={styles.infoContent}>
              <p style={styles.problemTitle}>{item.problem}</p>
              <p style={styles.paragraph}>{item.consequence}</p>
              <button 
                style={{...styles.button, backgroundColor: item.buttonColor}} 
                onClick={() => onAccess(item.title)}
              >
                Cadastre Seu Pet Grátis Agora
              </button>
            </div>
          </div>
        ))}
      </div>
      <div style={styles.finalWarning}>
        <p style={styles.warningText}>
          🏥 Cuide, Proteja, Ame - Tudo em um Único Lugar!
        </p>
      </div>
    </div>
  );
};

const painPointItems = [
  {
    img: petListImg,
    alt: "Organização",
    problem: "Cadastro Inteligente",
    title: "Lista de Pets",
    consequence: "Tenha todas as informações dos seus pets centralizadas e de fácil acesso.",
    buttonColor: '#FF6347'
  },
  {
    img: carteirinhaImg,
    alt: "Identificação Completa",
    problem: "Carteirinha Digital",
    title: "Cadastro da Carteirinha Digital",
    consequence: "Tenha identificação rápida e completa para seu pet em qualquer situação.",
    buttonColor: '#FF4500'
  },
  {
    img: vacinacoesImg,
    alt: "Controle de Saúde",
    problem: "Vacinação Controlada",
    title: "Vacinações",
    consequence: "Mantenha o histórico de vacinação atualizado e proteja a saúde do seu pet.",
    buttonColor: '#FF340A'
  },
  {
    img: consultasImg,
    alt: "Acompanhamento Médico",
    problem: "Consultas Organizadas",
    title: "Consultas",
    consequence: "Agende e acompanhe consultas veterinárias com facilidade e antecedência.",
    buttonColor: '#DC143C'
  },
  {
    img: examesImg,
    alt: "Monitoramento de Saúde",
    problem: "Exames Estratégicos",
    title: "Exames",
    consequence: "Mantenha um registro completo dos exames e acompanhe a saúde do seu pet.",
    buttonColor: '#B22222'
  },
  {
    img: qrCodeImg,
    alt: "Identificação Moderna",
    problem: "QR Code de Segurança",
    title: "Impressão",
    consequence: "Utilize QR Code e carteirinha digital para identificação rápida e moderna.",
    buttonColor: '#8B0000'
  },
];

const styles = {
  dashboardHome: {
    padding: '20px',
    backgroundColor: '#FFF5F5',
    borderRadius: '8px',
  },
  warningTitle: {
    color: '#FF6347',
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: '15px',
  },
  paragraph: {
    color: '#333',
    fontSize: '16px',
    lineHeight: '1.6',
    textAlign: 'center',
  },
  problemTitle: {
    color: '#FF4500',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
  },
  infoSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: '20px',
  },
  infoItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1 1 30%',
    margin: '15px',
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '12px',
    boxShadow: '0 6px 12px rgba(255, 0, 0, 0.1)',
    border: '2px solid #FFD1D1',
    transition: 'transform 0.3s ease',
  },
  infoContent: {
    textAlign: 'center',
    marginTop: '15px',
  },
  infoImage: {
    maxWidth: '100px',
    height: 'auto',
    borderRadius: '8px',
  },
  button: {
    color: 'white',
    padding: '12px 25px',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: '16px',
    marginTop: '15px',
    transition: 'background-color 0.3s ease',
  },
  finalWarning: {
    backgroundColor: '#FFE4E1',
    padding: '15px',
    marginTop: '20px',
    borderRadius: '8px',
    textAlign: 'center',
  },
  warningText: {
    color: '#8B0000',
    fontSize: '18px',
    fontWeight: 'bold',
  },
};

export default DashboardHome;